/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, ButtonProps } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ICMSButtonProps extends ButtonProps {
  buttonColor: 'black' | 'red' | 'blue' | 'green' | 'grey' | 'lg-blue' | 'purple'
  icon?: JSX.Element
}

interface IDictButton {
  key: ICMSButtonProps['buttonColor']
  backgroundColor: string
}

const CMSButton = ({ children, buttonColor, icon, ...props }: ICMSButtonProps): JSX.Element => {
  const iconElement = icon ? icon : null
  const BLACK_COLOR = '#002140'

  const isDisabled = props.disabled || false

  const [chosenColor, setChosenColor] = useState(BLACK_COLOR)

  const dict: IDictButton[] = [
    {
      key: 'black',
      backgroundColor: BLACK_COLOR,
    },
    {
      key: 'red',
      backgroundColor: '#ff4d4f',
    },
    {
      key: 'blue',
      backgroundColor: '#1890ff',
    },
    {
      key: 'green',
      backgroundColor: '#52c41a',
    },
    {
      key: 'grey',
      backgroundColor: '#8c8c8c',
    },
    {
      key: 'lg-blue',
      backgroundColor: '#0d6efd',
    },
    {
      key: 'purple',
      backgroundColor: '#702963',
    },
  ]

  useEffect(() => {
    if (!buttonColor) {
      setChosenColor(BLACK_COLOR)
      return
    }
    const foundColor = dict.find((item) => item.key === buttonColor)
    setChosenColor(foundColor?.backgroundColor || BLACK_COLOR)
  }, [])

  // function that convert hex to rgb
  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }

  const rgbToRgba = (rgb: { r: number; g: number; b: number }, opacity: number) => {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`
  }

  const hexToRgba = (hex: string, opacity: number) => rgbToRgba(hexToRgb(hex)!, opacity)

  const showHoverCSS =
    !isDisabled &&
    `&:hover {
    transform: scale(1.03);
    background-color: ${hexToRgba(chosenColor, 0.85)} !important;
    border-color: ${hexToRgba(chosenColor, 0.6)} !important;
    color: #ffffff !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  }
  &:focus {
    background-color: ${hexToRgba(chosenColor, 0.7)} !important;
    border-color: #ffffff;
    color: #ffffff;
    &:hover {
      background-color: #ffffff !important;
      border-color: #002140 !important;
      color: #002140 !important;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
    }
  }`

  const ButtonGlobal = styled(Button)`
    background-color: ${chosenColor};
    border-color: ${hexToRgba(chosenColor, 0.3)};
    color: white !important;
    ${showHoverCSS}
  `

  return (
    <ButtonGlobal {...props}>
      {' '}
      {iconElement}
      {children}{' '}
    </ButtonGlobal>
  )
}

export default CMSButton
