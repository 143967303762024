import * as dotenv from 'dotenv'
dotenv.config()

const service = {
  NAME: process.env.REACT_APP_SERVICE_NAME || 'cms',
}

const authService = {
  URL: process.env.REACT_APP_TS_AUTHENTICATION_URL,
  AUTH_PROJECT: process.env.REACT_APP_AUTH_PROJECT,
}

const restfulService = {
  URL: process.env.REACT_APP_TS_RESTFUL_API_URL,
}

const uploadService = {
  URL: process.env.REACT_APP_TS_UPLOAD,
}

const evchargerService = {
  URL: process.env.REACT_APP_TS_EV_CHARGER,
}

const googleMaps = {
  API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
}

const secret = {
  key: process.env.REACT_APP_SECRET || '',
}

const config = {
  service,
  authService,
  restfulService,
  uploadService,
  evchargerService,
  googleMaps,
  secret,
}

export default config
