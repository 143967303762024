import { Row, RowProps, Spin } from 'antd'

export const RowCenter = ({ children, ...props }: RowProps): JSX.Element => {
  return (
    <Row {...props} justify="center" align="middle" style={{ textAlign: 'center' }}>
      {children}
    </Row>
  )
}
export const RowBetween = ({ children, ...props }: RowProps): JSX.Element => {
  return (
    <Row {...props} justify="space-between" align="middle" style={{ textAlign: 'center' }}>
      {children}
    </Row>
  )
}
export const RowAround = ({ children, ...props }: RowProps): JSX.Element => {
  return (
    <Row {...props} justify="space-around" align="middle" style={{ textAlign: 'center' }}>
      {children}
    </Row>
  )
}
export const RowCenterMY1 = ({ children, ...props }: RowProps): JSX.Element => {
  return (
    <Row
      {...props}
      justify="center"
      align="middle"
      style={{ textAlign: 'center', margin: '1rem 0' }}
    >
      {children}
    </Row>
  )
}

interface SpinCenterProps extends RowProps {
  size?: 'small' | 'default' | 'large'
}

export const SpinCenter = ({ children, size, ...props }: SpinCenterProps): JSX.Element => {
  return (
    <Row
      {...props}
      justify="center"
      align="middle"
      style={{ textAlign: 'center', margin: '1rem 0' }}
    >
      <Spin size={size} />
      {children}
    </Row>
  )
}
