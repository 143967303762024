/* eslint-disable @typescript-eslint/ban-types */
import { Layout } from 'antd'
import 'antd/dist/antd.less'
import { PropsWithChildren } from 'react'
import { useAuth } from '../../contexts/Auth'
import '../../static/css/index.less'
import HeaderMenu from '../HeaderMenu/HeaderMenu'

const { Content } = Layout

export default function Page({ children }: PropsWithChildren<{}>): JSX.Element {
  const authContext = useAuth()

  // const [collapsed, setCollapsed] = useState(false)
  // const onCollapse = (): void => setCollapsed((prevCollapse) => !prevCollapse)
  return (
    <>
      <Layout style={{ height: '100vh' }}>
        <Layout className="site-layout" style={{ height: '100vh' }}>
          <HeaderMenu username={authContext.user.username as string} location={children} />
          <Content style={{ margin: '16px', overflow: 'auto' }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360, height: '100%', overflow: 'auto' }}
            >
              {children}
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center', height: 100 }}>Developed by HAUP</Footer> */}
        </Layout>
      </Layout>
    </>
  )
}
