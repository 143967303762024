import { Alert, PageHeader, Result, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AxiosFunction } from 'src/axios.function'
import { HaupLayout } from 'src/components/Layout/HaupLayout'
import { ChangePassword, FormValues } from './components/ChangePassword'
import { EmailChecking } from './components/EmailChecking'
import { OtpValidation } from './components/OtpValidation'

export const saveOtpJwtToken = (jwtToken: string): void => {
  localStorage.setItem('fg_psw', jwtToken)
}

export const getOtpJwtToken = (key = 'fg_psw'): string => {
  return localStorage.getItem(key) || ''
}

export default function ForgetPasswordPage(): JSX.Element {
  const history = useHistory()
  const [step, setStep] = useState<1 | 2 | 3>(1)
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const sendOtpEmail = async (email: string) => {
    setLoading(true)
    return AxiosFunction({
      method: 'post',
      url: '/connect/auth/forgot-password/send-otp',
      body: { email },
      queryParams: {},
    })
      .then((res) => {
        const { data, result, message } = res
        // add token to url query
        if (result) {
          window.location.href = `/forgot-password?token=${data.token}`
          setErrorMessage('')
          setStep(2)
          setEmail(email)
        } else {
          setErrorMessage(message)
        }
      })
      .catch((err) => {
        setErrorMessage(err)
      })
      .finally(() => setLoading(false))
    // save api
  }

  useEffect(() => {
    const token = window.location.search.split('=')[1]
    if (token) setStep(2)
  }, [])

  const checkOtp = async (otp: string): Promise<boolean> => {
    const token = window.location.search.split('=')[1]
    setLoading(true)
    return AxiosFunction({
      method: 'post',
      url: '/connect/auth/forgot-password/verify-otp',
      body: { otp, token },
      queryParams: {},
    })
      .then((res) => {
        const { result, message } = res
        if (result) {
          setErrorMessage('')
          setStep(3)
          return true
        } else {
          setErrorMessage(message)
          return false
        }
      })
      .catch((err) => {
        setErrorMessage(err)
        return false
      })
      .finally(() => setLoading(false))
  }

  const onResetPassword = (values: FormValues) => {
    const token = window.location.search.split('=')[1]
    setLoading(true)
    return AxiosFunction({
      url: '/connect/auth/reset-password',
      method: 'post',
      body: { ...values, token },
      queryParams: {},
    })
      .then((res) => {
        const { result } = res
        if (result) {
          setIsResetPassword(true)
          saveOtpJwtToken('')
        }
      })
      .catch((err) => {
        setErrorMessage(err)
      })
      .finally(() => setLoading(false))
  }

  const componentSteps: Record<1 | 2 | 3, JSX.Element> = {
    1: <EmailChecking onEmailOtpSent={sendOtpEmail} />,
    2: <OtpValidation onOtpValidation={checkOtp} />,
    3: <ChangePassword onPasswordChange={onResetPassword} />,
  }

  useEffect(() => {
    if (!isResetPassword) return
    const timeout = setTimeout(() => {
      history.push('/login')
    }, 3000)
    return () => clearTimeout(timeout)
  }, [isResetPassword])

  if (isResetPassword)
    return (
      <Result
        status="success"
        title="Password successfully reset!"
        subTitle="You will be redirected in a few seconds to the login page!"
      />
    )

  return (
    <HaupLayout>
      {step !== 3 && (
        <PageHeader
          style={{ margin: 0 }}
          title="Forgot Password"
          onBack={() => history.replace('/login')}
        />
      )}
      <Spin spinning={loading}>
        <Space direction="vertical">
          {componentSteps[step]} {errorMessage && <Alert type="error" message={errorMessage} />}
        </Space>
      </Spin>
    </HaupLayout>
  )
}
