import { SelectProps } from 'antd'
import { useParams } from 'react-router'
import { AxiosFunction } from 'src/axios.function'
import { BaseSelect } from 'src/components/Select/BaseSelect'
import { BaseOption } from 'src/interfaces'

type BuildingSelectProps = SelectProps<number> & { value?: number }

export const TenantBuildingSelect = ({ ...props }: BuildingSelectProps): JSX.Element => {
  const { companyCode } = useParams<{ companyCode: string }>()

  const fetchOptions = () =>
    AxiosFunction({
      method: 'get',
      url: `/connect/tenant/package/${companyCode}/options/buildings`,
      queryParams: {},
    }).then((res: { data: BaseOption[] }) => res.data)

  return <BaseSelect fetchOptions={fetchOptions} {...props} />
}
