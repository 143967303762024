import { Alert, Form } from 'antd'
import { InputOTP } from 'antd-input-otp'
import { useState } from 'react'
import CMSButton from 'src/components/Styles/button.style'
import { RowCenter } from 'src/components/Styles/row.style'

type OtpValidationProps = {
  onOtpValidation: (otp: string) => Promise<boolean>
}

const OtpValidation = ({ onOtpValidation }: OtpValidationProps): JSX.Element => {
  const [form] = Form.useForm()
  const [errorMessage, setErrorMessage] = useState('')

  const handleFinish = async (values: { otp: string[] }) => {
    const otp = values.otp.join('')
    const isValid = await onOtpValidation(otp)
    if (!isValid) setErrorMessage('An error occurred. Please try again.')
  }

  return (
    <>
      {errorMessage && (
        <Alert style={{ marginBlock: '0.25rem' }} type="error" message={errorMessage} />
      )}
      <Form onFinish={handleFinish} form={form}>
        <Form.Item label="OTP" name="otp">
          <InputOTP autoSubmit={form} inputType="numeric" />
        </Form.Item>

        <RowCenter>
          <CMSButton buttonColor="green" onClick={() => form.submit()}>
            Submit
          </CMSButton>
        </RowCenter>
      </Form>
    </>
  )
}

export { OtpValidation }
