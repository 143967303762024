/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserOutlined } from '@ant-design/icons'
import { Col, Layout, Popover, Row, Typography } from 'antd'
import Clock from 'react-live-clock'
import { Link } from 'react-router-dom'
import { theme } from 'src/config/style'
import styled from 'styled-components'
import logo from './logo3.png'

const { Title } = Typography

// ----------- TYPES -------------
interface IHeader {
  headerText?: any
  username: string
  location: any
}

interface IHeaderTitle {
  link: string
  title: string
  subMenu: any
}

interface IHeaderTitle {
  link: string
  title: string
}

const { Header } = Layout

// Screen size
const screen_md_size = '768px'

// -------------- STYLES COMPONENTS ------------
const HeaderText = styled.div`
  color: white;
  font-size: 1.25rem;
  text-shadow: 6px 6px 7px black;
  @media (max-width: ${screen_md_size}) {
    text-align: center;
  }
`
const IconUserInfo = styled(UserOutlined)`
  font-size: 40px;
  @media (max-width: ${screen_md_size}) {
    display: none;
  }
`
const IconUser = styled(UserOutlined)`
  font-size: 30px;
  color: white;
`
const LoggedIn = styled.div`
  margin: 0;
  color: white;
  font-size: 1rem;
  @media (max-width: 768px) {
    display: none;
  }
`

export default function App({ username, location, headerText }: IHeader): JSX.Element {
  const titleHeader: JSX.Element[] = []
  const locationNow: string = location.pathname

  headerText?.map((item: IHeaderTitle) => {
    const { link, title, subMenu } = item || {}
    if (subMenu) {
      subMenu.map((item: any) => {
        const { link, title } = item || {}
        if (link === locationNow) {
          titleHeader.push(<HeaderText>{title}</HeaderText>)
        }
      })
    }
    if (link === locationNow) {
      titleHeader.push(<HeaderText>{title}</HeaderText>)
    }
  })

  const content = (
    <>
      <Row style={{ maxWidth: '100%' }}>
        <LoggedIn>
          <Col md={6} sm={8} xs={8} style={{ marginRight: 10 }}>
            <IconUserInfo />
          </Col>
        </LoggedIn>
        <Col md={18} sm={16} xs={16}>
          <Row>
            <strong>Logged in as:</strong> {username}
          </Row>
          <Row>
            <Link to="/logout">Logout</Link>
          </Row>
        </Col>
      </Row>
    </>
  )

  return (
    <Header
      style={{
        padding: '0 1rem',
        display: 'flex',
        backgroundColor: theme.haupBlue,
        justifyContent: 'space-between',
        alignContent: 'center',
      }}
    >
      <div>
        <Link to="/">
          <img src={logo} width="40px" style={{ backgroundColor: theme.haupBlue }} />
        </Link>
      </div>

      <div style={{ display: 'flex', alignContent: 'center', gap: '1rem', alignItems: 'center' }}>
        <Title level={5} style={{ margin: 0, color: 'white' }}>
          <Clock format={'dddd, MMMM DD, yyyy | HH:mm'} ticking={true} timezone={'Asia/Bangkok'} />
        </Title>

        <Popover placement="bottomRight" content={content} trigger="hover">
          <IconUser />
        </Popover>
      </div>
    </Header>
  )
}
