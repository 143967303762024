// import { LinkOutlined } from '@ant-design/icons'
import { Card, List, Typography } from 'antd'
// import { Link } from 'react-router-dom'
// import { menus } from '../../config/menu'
// import { theme } from '../../config/style'
import { Link } from 'react-router-dom'
import { RowBetween } from 'src/components/Styles/row.style'
import { menus } from 'src/config/menu'
import { theme } from 'src/config/style'
import { useAuth } from '../../contexts/Auth'
import { ReadTenantConditions } from './ReadTenantConditions'

const { Title } = Typography

export const HomePage = (): JSX.Element => {
  const { user } = useAuth()
  const { companyCode, buildingCode, username, role } = user

  const validMenus = menus.length
    ? menus.filter((menu) => {
        if (!role) return false
        return menu.auth === role.toLowerCase()
      })
    : []

  const menuList = validMenus.map((menu) => {
    const subMenu = menu.subMenu.map((item) => {
      const link = item.link
        .replace(':companyCode', companyCode || '')
        .replace(':buildingCode', buildingCode || '')
      return { ...item, link }
    })
    return { ...menu, subMenu }
  })

  const isTenant = role === 'TENANT'
  const purchaseConditions = isTenant ? <ReadTenantConditions /> : <></>

  return (
    <div>
      <RowBetween>
        <Typography.Title>Welcome {username}</Typography.Title>
        {purchaseConditions}{' '}
      </RowBetween>
      <hr />
      <List
        dataSource={menuList}
        grid={{ gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 4, xxl: 4 }}
        style={{ width: '100%', marginTop: '1rem' }}
        renderItem={({ title, subMenu }) => {
          return (
            <List.Item>
              <Card
                title={title}
                headStyle={{ background: theme.haupBlue, color: 'white' }}
                bodyStyle={{ border: '3px solid ' + theme.haupBlue }}
              >
                {subMenu.map((item) => (
                  <Card.Grid key={item.key} hoverable={true} style={{ width: '100%' }}>
                    <Link to={item.link} key={item.key}>
                      <Title level={4}>
                        {'>'} {item.title}
                      </Title>
                    </Link>
                  </Card.Grid>
                ))}
              </Card>
            </List.Item>
          )
        }}
      />
    </div>
  )
}
