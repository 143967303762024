import { lazy } from 'react'
import { HomePage } from 'src/containers/Home'
import {
  ADMIN_PACKAGES_DASHBOARD,
  FORGOT_PASSWORD,
  TENANT_CREATE_BOOKING,
  TENANT_PACKAGE,
  TENANT_PACKAGE_PURCHASES,
  TENANT_REGISTRATION,
} from './paths'
// import ReservationManage from '../containers/ReservationManage'
// import DetailModel from '../containers/VehicleMaster/VehicleModel/'

export type RouteItem = {
  title: string
  path: string
  requireAuth: 'owner' | 'tenant' | 'both' | false
  container: React.LazyExoticComponent<() => JSX.Element> | (() => JSX.Element)
}

const routes: RouteItem[] = [
  {
    title: 'Home',
    path: '/',
    requireAuth: 'both',
    container: HomePage,
  },
  {
    title: 'Dashboard',
    path: ADMIN_PACKAGES_DASHBOARD,
    requireAuth: 'owner',
    container: lazy(() => import('../containers/Owner/OwnerDashboard')),
  },
  {
    title: 'Forgot password',
    path: FORGOT_PASSWORD,
    requireAuth: false,
    container: lazy(() => import('../containers/Auth/ForgotPassword')),
  },
  {
    title: 'Tenant registration',
    path: TENANT_REGISTRATION,
    requireAuth: false,
    container: lazy(() => import('../containers/Auth/TenantRegister')),
  },
  {
    title: 'Tenant package',
    path: TENANT_PACKAGE,
    requireAuth: 'tenant',
    container: lazy(() => import('../containers/Tenant/TenantPackageList')),
  },
  {
    title: 'Tenant Package Purchased',
    path: TENANT_PACKAGE_PURCHASES,
    requireAuth: 'tenant',
    container: lazy(() => import('../containers/Tenant/MyPackagePurchased')),
  },
  {
    title: 'Create Booking',
    path: TENANT_CREATE_BOOKING,
    requireAuth: 'tenant',
    container: lazy(() => import('../containers/Tenant/Booking/Create')),
  },
]

export default routes
