import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ForgetPasswordPage from 'src/containers/Auth/ForgotPassword'
import TenantRegistrationPage from 'src/containers/Auth/TenantRegister'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import routeconfig, { RouteItem } from '../../config/route'
import Login from '../../containers/Auth/Login'
import Logout from '../../containers/Auth/Logout'
import AuthenticatedPage from '../AuthenticatedPage'
import Page from '../Page/Page'

const exactRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/:companyCode/register',
    component: TenantRegistrationPage,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/forgot-password',
    component: ForgetPasswordPage,
  },
]

const Router = (): JSX.Element => {
  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <Switch>
        {exactRoutes.map((route) => (
          <Route exact path={route.path} key={route.path}>
            <route.component />
          </Route>
        ))}

        {routeconfig.map((item: RouteItem) => {
          if (item.requireAuth) {
            return (
              <Route exact path={item.path} key={item.title}>
                <AuthenticatedPage authMode={item.requireAuth}>
                  <Page>
                    <Suspense fallback={<div>Loading...</div>}>
                      <item.container />
                    </Suspense>
                  </Page>
                </AuthenticatedPage>
              </Route>
            )
          } else {
            return (
              <Route exact path={item.path} key={item.title}>
                <Page>
                  <Suspense fallback={<div>Loading...</div>}>
                    <item.container />
                  </Suspense>
                </Page>
              </Route>
            )
          }
        })}
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </QueryParamProvider>
  )
}

export default Router
