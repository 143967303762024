import { AnyAction } from 'redux'

export interface CountData {
  _id: string
  count: number
}

export default (state: CountData[] = [], action: AnyAction): CountData[] => {
  switch (action.type) {
    case 'SET_COUNT':
      return action.payload
    default:
      return state
  }
}
