/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios'
import config from 'src/config'
import { getUserToken } from 'src/contexts/Auth'
import { AuthMode, GetPermissionResponse, PermissionAccessStatus, TenantUser } from 'src/interfaces'

const { restfulService } = config

export class AuthPermission {
  private _companyCode: string
  private _buildingCode: string
  private _authMode: AuthMode
  constructor(authMode: AuthMode) {
    this._authMode = authMode
    // const { companyCode, buildingCode } = useParams<{ companyCode: string; buildingCode: string }>()
    const { companyCode, buildingCode } = this.retrieveParams(authMode)
    this._companyCode = companyCode
    this._buildingCode = buildingCode
  }

  private getTenantCodes(): { companyCode: string; buildingCode: string } {
    const path = window.location.pathname
    const [companyCode, buildingCode] = path.split('/').splice(2) // remove the first two elements ['', 'tenant']
    return { companyCode, buildingCode }
  }

  private getAdminCompanyCode() {
    const path = window.location.pathname
    const regex = /admin\/(\w+)(\/\w+)*\/?/
    const match = path.match(regex)
    if (match) return match[1]
    return ''
  }

  private retrieveParams(authMode: AuthMode) {
    if (authMode === 'owner') return { companyCode: this.getAdminCompanyCode(), buildingCode: '' }
    if (authMode === 'tenant') return this.getTenantCodes()
    return { companyCode: '', buildingCode: '' }
  }

  private _getAxiosUrl(): string {
    const urls: Record<AuthMode, string> = {
      owner: `/connect/auth/current-owner`,
      tenant: `/connect/auth/current-tenant`,
      both: `/connect/auth/current-user`, // not implemented yet
    }
    return urls[this._authMode]
  }

  private _getAxiosQueryParams(): Record<string, string> {
    const queryParams: Record<AuthMode, Record<'code' | 'building', string>> = {
      owner: { code: this._companyCode, building: '' },
      tenant: { code: this._companyCode, building: this._buildingCode },
      both: { code: this._companyCode, building: this._buildingCode }, // not implemented yet
    }
    return queryParams[this._authMode]
  }

  async verify(): Promise<GetPermissionResponse> {
    const url = `${restfulService.URL}${this._getAxiosUrl()}`
    const accessToken = getUserToken()
    const queryParams = this._getAxiosQueryParams()
    return await axios
      .get(url, {
        params: queryParams,
        headers: {
          Authorization: accessToken || undefined,
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      })
      .then((res) => {
        const userData = res.data.data
        return { result: 'AUTHORIZED' as PermissionAccessStatus, data: userData as TenantUser }
      })
      .catch((error: AxiosError) => {
        const statusResponse: Record<number, GetPermissionResponse['result']> = {
          401: 'UNAUTHORIZED',
          403: 'FORBIDDEN',
          500: 'SERVER_ERROR',
          404: 'SERVER_ERROR',
        }
        return {
          result:
            statusResponse[error?.response?.status as number] ??
            ('SERVER_ERROR' as PermissionAccessStatus),
          data: {} as TenantUser,
        }
      })
  }
}
