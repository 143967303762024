import { Button, Result } from 'antd'
import { ResultStatusType } from 'antd/lib/result'
import { Link } from 'react-router-dom'

type Props = {
  status: ResultStatusType | '401'
  title?: string
}
const UnAuthorizedPage = ({ status, title }: Props): JSX.Element => {
  // ant design does not have 401 system, so I change the code to 403 to render Result component but with unauthorized title coming from 401 HTTP status code
  const statusCode = status === '401' ? '403' : status
  return (
    <div
      style={{
        backgroundColor: 'rgba(128,128,128,0.05)',
        height: '100vh',
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Result
        status={statusCode}
        title={title || 'Forbidden'}
        subTitle={status === '500' ? 'Please try again.' : 'You cannot access this page.'}
        extra={
          <Link to="/">
            <Button type="primary"> {status === '500' ? 'Refresh' : 'Back Home'}</Button>
          </Link>
        }
      />
    </div>
  )
}

export { UnAuthorizedPage }
