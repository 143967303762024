import { Col, Layout, Row, Space, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { clearSession } from 'src/contexts/Auth'
import Loginbg from '../../../static/img/loginbg.jpg'
import LogoHaup from '../../../static/img/logo2.png'

const { Title } = Typography

const Logout = (): JSX.Element => {
  const [isLoggedOut, setIsLoggedOut] = useState(false)
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    clearSession().then((isLoggedOut) => {
      setIsLoggedOut(isLoggedOut)
    })
  }, [])

  if (isLoggedOut) return <Redirect to="/login" />

  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundImage: `url(${Loginbg})`,
        backgroundSize: 'cover',
      }}
    >
      <Row justify="space-around" align="middle" style={{ minHeight: '100vh' }}>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Row style={{ minWidth: '100%' }} justify="center">
            <Col span={8} style={{ textAlign: 'center' }}>
              <img src={LogoHaup} height="40vh" />
            </Col>
          </Row>
          <Row style={{ minWidth: '100%' }} justify="center">
            <Col
              span={8}
              style={{ backgroundColor: 'white', textAlign: 'center', padding: '50px' }}
            >
              <Title>You are logged out</Title>
            </Col>
          </Row>
          <Row style={{ minWidth: '100%' }} justify="center">
            <Col span={8} style={{ textAlign: 'center', color: 'white' }}>
              <p>© {currentYear} Haupcar Company Limited.</p>
            </Col>
          </Row>
        </Space>
      </Row>
    </Layout>
  )
}

export default Logout
