import { Alert, Form, Input } from 'antd'
import { validate as emailValidate } from 'email-validator'
import { useState } from 'react'
import CMSButton from 'src/components/Styles/button.style'
import { RowCenter } from 'src/components/Styles/row.style'

type FormValues = {
  email: string
}

type EmailCheckingProps = {
  onEmailOtpSent: (email: string) => void
}

const EmailChecking = ({ onEmailOtpSent }: EmailCheckingProps): JSX.Element => {
  const [form] = Form.useForm()
  const [emailSent, setEmailSent] = useState(false)

  const sendOtpEmail = async (body: FormValues) => {
    setEmailSent(true)
    onEmailOtpSent(body.email)
  }

  if (emailSent)
    return (
      <Alert type="success" message="A message was sent to your mobile if the email was correct!" />
    )
  return (
    <Form form={form} onFinish={sendOtpEmail}>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
          () => ({
            validator(_, value) {
              if (emailValidate(value)) return Promise.resolve()
              else return Promise.reject('Invalid email!')
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <RowCenter>
        <CMSButton buttonColor="green" onClick={() => form.submit()}>
          SEND EMAIL
        </CMSButton>
      </RowCenter>
    </Form>
  )
}

export { EmailChecking }
