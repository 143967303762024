/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import moment, { Moment } from 'moment'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router'
import { UnAuthorizedPage } from 'src/components/AuthenticatedPage/UnAuthorizedPage'
import {
  Auth,
  AuthMode,
  GetPermissionResponse,
  PermissionAccessStatus,
  UserData,
} from 'src/interfaces'
import { checkValidToken } from '../../services/auth'
import { AuthPermission } from '../../services/auth/permission/AuthPermission'
import loadingIcon from '../../static/img/haup-loading.gif'

const defaultValues: Auth = {
  user: {
    userid: null,
    username: null,
    lastname: '',
    name: '',
    mobile: '',
    mobilecountrycode: '',
    registertime: '',
    activated: 'FALSE',
    adminid: 0,
    companyCode: undefined,
    buildingCode: undefined,
    role: undefined,
    tenantId: undefined,
  },
  userLoggedIn: false,
  accessToken: '',
  mode: 'both',
}

const AuthContext = React.createContext(defaultValues)

type AuthProviderProps = {
  authMode: AuthMode
}

type AuthError =
  | {
      status: '403' | '500' | '401'
      text: string
      isError: true
    }
  | {
      isError: false
      text?: undefined
      status?: undefined
    }

type UserProvider = {
  user: Auth['user']
  accessToken: Auth['accessToken']
  permission: GetPermissionResponse['result']
}

const AuthProvider = ({
  children,
  authMode,
}: PropsWithChildren<AuthProviderProps>): JSX.Element => {
  const [user, setUser] = useState({} as UserProvider)
  const _permission = useRef<PermissionAccessStatus | null>(null)
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const _isMounted = useRef(false)
  const readyLogin = useRef(false)

  const [error, setError] = useState<AuthError>({ isError: false })

  const isUserLoggedIn = userLoggedIn

  const setErrors = (permission: PermissionAccessStatus): void => {
    if (permission === 'AUTHORIZED') return
    const errorStatus: Record<Partial<PermissionAccessStatus>, [string, string]> = {
      SERVER_ERROR: ['500', 'Internal Server Error'],
      UNAUTHORIZED: ['401', 'Unauthorized'],
      FORBIDDEN: ['403', 'Forbidden'],
      AUTHORIZED: ['200', 'Authorized'],
    }
    setError({
      isError: true,
      status: errorStatus[permission][0] as any,
      text: errorStatus[permission][1],
    })
    return
  }

  const checkToken = async () => {
    const token = await checkAuthStatus().catch(() => null)
    if (!token) {
      await clearSession()
      setUserLoggedIn(false)
    }
    const { result, data: permissionData } = await getPermissionData(
      authMode,
      token as NonNullable<string>,
    )
    setErrors(result)

    if (_isMounted.current) {
      setUserLoggedIn(token ? true : false)
      const userData = await getUserData()
      const userToken = await getUserToken()
      readyLogin.current = true
      const userInformation = userData ? userData : ({} as UserData)
      setUser((data) => {
        return {
          ...data,
          user: { ...userInformation, ...permissionData },
          // permission,
          accessToken: userToken as NonNullable<string>,
        }
      })
    }
  }

  useEffect(() => {
    _isMounted.current = true
    checkToken()
    return () => {
      _isMounted.current = false
    }
  }, [])

  if (error.isError) {
    return <UnAuthorizedPage key="Unauthorized" status={error.status} title={error.text} />
  }
  if (!readyLogin.current) return <HaupLoading key="Loading" />
  if (!isUserLoggedIn) return <Redirect to="/login" key="LoginRedirect" />

  return (
    <AuthContext.Provider
      value={{
        user: user.user,
        userLoggedIn,
        mode: authMode,
        accessToken: user.accessToken,
      }}
      key="AuthProvider"
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): Auth {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export async function setUserToken(userToken: string): Promise<boolean> {
  try {
    await localStorage.setItem('userToken', JSON.stringify(userToken))
    return true
  } catch (error) {
    return false
  }
}

export async function setUserData(userData: UserData): Promise<boolean> {
  try {
    await localStorage.setItem('userData', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function setLogTime(userData: UserData): Promise<boolean> {
  try {
    await localStorage.setItem('logTime', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function getUserData(): Promise<UserData | false> {
  try {
    const userData = await localStorage.getItem('userData')
    if (userData) {
      return JSON.parse(userData)
    }
    return {} as UserData
  } catch (error) {
    return false
  }
}

export function getUserToken(): string | null {
  const tokenString = localStorage.getItem('userToken')
  if (!tokenString) return null
  return JSON.parse(tokenString)
}

export async function getLogTime(): Promise<Moment | null> {
  const logTime = (await localStorage.getItem('logTime')) || null
  if (logTime) {
    return moment(logTime)
  }
  return null
}

export function savePermission(permission: boolean | null): void {
  return localStorage.setItem('permission', JSON.stringify(permission))
}

export async function getPermission(): Promise<boolean | null> {
  const permission = (await localStorage.getItem('permission')) || null
  if (permission) return JSON.parse(permission)
  return null
}

export async function checkAuthStatus(): Promise<Token | null> {
  const userToken = await getUserToken()
  if (!userToken) return null
  const isValidToken = await checkValidToken(userToken)
  return isValidToken ? userToken : null
}

export async function getPermissionData(
  authMode: AuthMode,
  token?: string,
): Promise<GetPermissionResponse> {
  const userToken = token ? token : await getUserToken()
  if (!userToken)
    return {
      result: 'UNAUTHORIZED',
      data: {
        role: undefined,
        companyCode: undefined,
        buildingCode: undefined,
        tenantId: undefined,
      },
    }
  const permissionService = new AuthPermission(authMode)
  const response = await permissionService.verify()
  return response
}

export async function clearSession(): Promise<boolean> {
  await localStorage.clear()
  return true
}

export { AuthContext, AuthProvider, useAuth }

export const HaupLoading = (): JSX.Element => {
  return (
    <div
      style={{
        backgroundColor: 'rgb(21, 73, 156)',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <img
        src={loadingIcon}
        style={{
          margin: 0,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '20vh',
        }}
      ></img>
    </div>
  )
}

type Token = string
