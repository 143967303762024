import axios from 'axios'
import {
  GetExchangeTokenResponse,
  GetUserTokenResponse,
  PostExchangeTokenResponse,
  Token,
} from 'src/interfaces'
import config from '../../config'

const { authService } = config

interface ICredential {
  username: string
  password: string
}

export async function getUserToken(credential: ICredential): Promise<GetUserTokenResponse> {
  return new Promise((resolve, reject) => {
    axios
      .post<GetUserTokenResponse>(`${authService.URL}/token/login`, {
        username: credential.username,
        password: credential.password,
        project: authService.AUTH_PROJECT,
        deviceType: 'WEBSITE',
      })
      .then(function (response) {
        const { data } = response
        const { accessToken } = data
        if (accessToken) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(reject)
  })
}

export async function checkValidToken(accessToken: string): Promise<Token | false> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/token/verify`, {
        accessToken,
        project: authService.AUTH_PROJECT,
      })
      .then(function (response) {
        const { data } = response
        const { token } = data
        if (token) {
          resolve(token)
        } else {
          reject(token)
        }
      })
      .catch(function (error) {
        const httpStatus = error?.response?.status
        if (httpStatus === 401) {
          resolve(false)
        }
        reject(error)
      })
  })
}

export async function exchangeProjectToken(
  accessToken: string,
  projectName: string,
): Promise<PostExchangeTokenResponse> {
  const exchangeTokenResponse = await axios.get<GetExchangeTokenResponse>(
    `${authService.URL}/token/exchange/${projectName}`,
    {
      headers: {
        Authorization: accessToken,
        project: projectName,
      },
    },
  )

  const { exchangeToken } = exchangeTokenResponse.data
  const loginResult = await axios.post<PostExchangeTokenResponse>(
    `${authService.URL}/token/exchange`,
    {
      exchangeToken: exchangeToken,
    },
  )
  return loginResult.data
}
