import { Divider, Modal, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CMSButton from 'src/components/Styles/button.style'
import { RowAround, RowCenter } from 'src/components/Styles/row.style'
import { useAuth } from 'src/contexts/Auth'

const { Title, Text } = Typography

export const ReadTenantConditions = (): JSX.Element => {
  const isHidden = localStorage.getItem('hidePackageConditions')
  const [showAppliedConditions, setShowAppliedConditions] = useState(false)
  const {
    user: { companyCode, buildingCode },
  } = useAuth()

  useEffect(() => {
    if (isHidden) return
    setShowAppliedConditions(true)
  }, [])

  const hideConditions = () => {
    localStorage.setItem('hidePackageConditions', 'true')
    setShowAppliedConditions(false)
  }
  return (
    <>
      <CMSButton buttonColor="black" onClick={() => setShowAppliedConditions(true)}>
        Show Conditions
      </CMSButton>
      <Modal
        open={showAppliedConditions}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        width="80%"
        onCancel={() => setShowAppliedConditions(false)}
        maskClosable={false}
        centered
      >
        <Divider>Purchase Airport transfer packages</Divider>

        <RowCenter>
          <Title level={5}>Select one of the two conditions</Title>{' '}
        </RowCenter>
        <section
          style={{ display: 'flex', gap: '3rem', padding: '1rem', border: '1px solid gray' }}
        >
          <div>
            <ol style={{ padding: '1rem' }}>
              <li>Call 02XXXXXXX (Customer service) or Line @HAUPcar</li>
              <li>Inform your email address</li>
              <li>Inform which packages that you would like to purchase</li>
              <li>
                Once purchase has been made, please check{' '}
                <Link
                  to={`/tenant/${companyCode}/${buildingCode}/package/my-purchases`}
                  style={{ color: 'blue', textDecoration: 'underline' }}
                >
                  "My purchases"
                </Link>{' '}
                in order to view your purchased packages
              </li>
            </ol>
          </div>
          <div>
            <ol style={{ padding: '1rem' }}>
              <li>
                Select to buy the package{' '}
                <Link
                  to={`/tenant/${companyCode}/${buildingCode}/package`}
                  style={{ color: 'blue', textDecoration: 'underline' }}
                >
                  Click here
                </Link>
              </li>
              <li>
                HAUP will confirm the purchase. Once payment has been completed, please view your
                purchase in{' '}
                <Link
                  to={`/tenant/${companyCode}/${buildingCode}/package/my-purchases`}
                  style={{ color: 'blue', textDecoration: 'underline' }}
                >
                  My purchases{' '}
                </Link>
              </li>
            </ol>
          </div>
        </section>
        <Divider>Book airport transfer</Divider>
        <RowAround>
          <div>
            <Title style={{ textAlign: 'center' }} level={5}>
              NO PACKAGE
            </Title>
            <ol style={{ padding: '1rem' }}>
              <li>
                Go to{' '}
                <Link to={`/`} style={{ color: 'blue', textDecoration: 'underline' }}>
                  Booking
                </Link>{' '}
                and click "Create" button
              </li>
              <li>Fill in the information</li>
              <li>The request will be sent to HAUP to be confirmed</li>
              <li>Once booking has been confirmed, it will be under "Reserved" menu</li>
            </ol>
          </div>
          <div>
            <Title style={{ textAlign: 'center' }} level={5}>
              USING PACKAGE
            </Title>
            <ol style={{ padding: '1rem' }}>
              <li>
                Go to{' '}
                <Link
                  to={`/tenant/${companyCode}/${buildingCode}/package/my-purchases`}
                  style={{ color: 'blue', textDecoration: 'underline' }}
                >
                  My Purchases
                </Link>{' '}
                page and select the package you want to use
              </li>
              <li>Click "Create booking" of that package that you want to use</li>
            </ol>
          </div>
        </RowAround>
        <RowCenter>
          <CMSButton buttonColor="black" onClick={hideConditions}>
            HIDE
          </CMSButton>
        </RowCenter>
      </Modal>
    </>
  )
}
