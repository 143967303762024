import { Result } from 'antd'
import { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router'
import { HaupLayout } from 'src/components/Layout/HaupLayout'
import { getPermissionData } from 'src/contexts/Auth'
import { TenantRegistrationForm } from './TenantRegistrationForm'

export default function TenantRegistrationPage(): JSX.Element {
  const [loggedIn, setLoggedIn] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)
  const history = useHistory()

  const onRegister = () => setIsRegistered(true)

  // check if user is a tenant and already have a token
  useEffect(() => {
    const checkPermission = async () => {
      const { result } = await getPermissionData('tenant')
      if (result === 'AUTHORIZED') setLoggedIn(true)
    }
    checkPermission()
  }, [])

  useEffect(() => {
    if (!isRegistered) return
    const timeout = setTimeout(() => {
      history.replace('/login')
      clearTimeout(timeout)
    }, 3000)
  }, [isRegistered])

  if (isRegistered)
    return (
      <div style={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
        <Result
          status="success"
          title="Properly registered!"
          subTitle="You will be redirected in a few seconds to the home page!"
        />
      </div>
    )

  if (loggedIn) return <Redirect to="/" />
  return (
    <>
      <HaupLayout>
        <TenantRegistrationForm onValidRegistration={onRegister} />
      </HaupLayout>
    </>
  )
}
