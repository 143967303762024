/* eslint-disable @typescript-eslint/no-unused-vars */
import { Select, SelectProps } from 'antd'
import { useEffect, useState } from 'react'
import { BaseOption } from 'src/interfaces'

interface BaseSelect extends SelectProps<number> {
  fetchOptions: () => Promise<BaseOption[]>
}

export const BaseSelect = ({ fetchOptions, ...props }: BaseSelect): JSX.Element => {
  const [selectLoading, setSelectLoading] = useState(false)
  const [options, setOptions] = useState<BaseOption[]>([])

  useEffect(() => {
    setSelectLoading(true)
    fetchOptions()
      .then((res: BaseOption[]) => {
        setOptions(res)
      })
      .catch(() => {
        // options is already empty no need to reset to []
      })
      .finally(() => setSelectLoading(false))
  }, [])

  return (
    <Select
      options={options}
      loading={selectLoading}
      {...props}
      showSearch
      optionFilterProp="label"
      filterOption={(input, option) => {
        const reg = new RegExp(input, 'gi')
        return reg.test(option?.label as string)
      }}
    />
  )
}
