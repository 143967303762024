import { Alert, Button, Form, Input, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AxiosFunction } from 'src/axios.function'
import { TenantBuildingSelect } from './BuildingSelect'
import * as tenantRules from './formRules'
const { Title } = Typography

type FormValues = {
  username: string
  password: string
  confirmPassword: string
  mobile: string
  firstName: string
  lastName: string
  buildingId: number
  companyCode: string
}

export function TenantRegistrationForm({
  onValidRegistration,
}: {
  onValidRegistration: () => void
}): JSX.Element {
  const [form] = Form.useForm()
  const { companyCode } = useParams<{ companyCode: string }>()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      companyCode,
      firstName: 'Ghislain',
      lastName: 'Nkengfack',
      username: 'ghislain.g@haupcar.com',
      password: '49655dhbnusjhdDu@di',
      confirmPassword: '49655dhbnusjhdDu@di',
      buildingId: 1,
      mobile: '0647888515',
    })
  }, [])

  const onRegister = async (values: FormValues): Promise<void> => {
    setLoading(true)
    // if properly registered => setLoggedIn(true)
    const response = await AxiosFunction({
      method: 'post',
      url: `/connect/auth/register-tenant`,
      body: values,
      queryParams: {},
    })
      .catch((err: string) => {
        setErrorMessage(err)
        setLoading(false)
        return false
      })
      .then((res) => {
        setLoading(false)
        return res.result || false
      })
    if (response) onValidRegistration()
  }

  useEffect(() => {
    form.setFieldValue('companyCode', companyCode)
  }, [])

  return (
    <>
      <Title level={3}>Tenant Registration</Title>
      {errorMessage && (
        <Alert
          message={errorMessage}
          type="error"
          showIcon
          onClose={() => setErrorMessage('')}
          closable
          style={{ marginBottom: '1rem' }}
        />
      )}
      <Form
        form={form}
        onFinish={(values: FormValues) => {
          onRegister(values)
        }}
      >
        <Form.Item name="username" label="Email" rules={tenantRules.email}>
          <Input autoComplete="email" />
        </Form.Item>
        <Form.Item name="firstName" label="First name" rules={tenantRules.name}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last name" rules={tenantRules.lastname}>
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Password" rules={tenantRules.password}>
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item name="confirmPassword" label="Confirm" rules={tenantRules.confirmPassword}>
          <Input.Password />
        </Form.Item>
        <Form.Item name="mobile" label="Mobile" rules={tenantRules.mobile}>
          <Input type="numeric" />
        </Form.Item>
        <Form.Item name="buildingId" label="Building" rules={tenantRules.building}>
          <TenantBuildingSelect />
        </Form.Item>
        <Form.Item label="Company" name="companyCode" rules={tenantRules.company}>
          <Input disabled />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        onClick={() => form.submit()}
        size="large"
        className="login-form-button"
        block
        loading={loading}
      >
        Register
      </Button>
    </>
  )
}
