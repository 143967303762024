import {
  // FileProtectOutlined,
  FormOutlined,
} from '@ant-design/icons'
import { ReactNode } from 'react'
import { AuthMode } from 'src/interfaces'
import { ADMIN_PACKAGES_DASHBOARD, TENANT_PACKAGE, TENANT_PACKAGE_PURCHASES } from './paths'

export type MainMenu = {
  title: string
  auth: AuthMode
  key: string
  icon?: ReactNode
  subMenu: MenuItems[]
}

export interface MenuItems {
  title: string
  icon?: ReactNode
  link: string
  key: string
  // subMenu?: Array<Omit<MenuItems, 'subMenu'>>
  // subMenu?: MenuItems[]
}

export const menus: MainMenu[] = [
  //*Airport transfer
  {
    title: 'Overview',
    icon: FormOutlined,
    auth: 'owner',
    key: 'owner-overview',
    subMenu: [
      {
        title: 'Dashboard',
        link: ADMIN_PACKAGES_DASHBOARD,
        key: 'owner-dashboard',
      },
    ],
  },
  {
    title: 'Package',
    auth: 'tenant',
    key: 'tenant-overview-package',
    subMenu: [
      {
        title: 'Buy package',
        link: TENANT_PACKAGE,
        key: 'tenant-package-list',
      },
      {
        title: 'My purchases',
        link: TENANT_PACKAGE_PURCHASES,
        key: 'tenant-package-purchases',
      },
    ],
  },
]
