import { PropsWithChildren } from 'react'
import { AuthMode } from 'src/interfaces'
import { AuthProvider } from '../../contexts/Auth'

type Props = {
  authMode: AuthMode
}

export default function AuthenticatedPage({
  authMode,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <>
      <AuthProvider authMode={authMode}>{children}</AuthProvider>
    </>
  )
}
