/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Rule } from 'antd/lib/form'
import { validate as emailValidator } from 'email-validator'

const email: Rule[] = [
  {
    required: true,
    message: 'Please input your email!',
  },
  { max: 100, message: 'Email is too long' },
  () => ({
    validator(_, value) {
      if (emailValidator(value)) return Promise.resolve()
      else return Promise.reject('Invalid email')
    },
  }),
]

const checkPassword = (text: string): string | null => {
  if (text.length < 8) return 'Password must contain at least 8 characters'
  if (!/[a-z]/.test(text)) return 'Password must contain at least one lowercase letter'
  if (!/[A-Z]/.test(text)) return 'Password must contain at least one uppercase letter'
  if (!/[0-9]/.test(text)) return 'Password must contain at least one number'
  if (!/[!@#$%^&*]/.test(text))
    return 'Password must contain at least one special character (!@#$%^&*)'
  return null
}

const password: Rule[] = [
  {
    required: true,
    message: 'Please input your password!',
  },
  () => ({
    validator(_, value) {
      const response = checkPassword(value)
      if (response) return Promise.reject(response)
      else return Promise.resolve()
    },
  }),
]

const confirmPassword: Rule[] = [
  {
    required: true,
    message: 'Please confirm your password!',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (value === getFieldValue('password')) return Promise.resolve()
      else return Promise.reject('Passwords do not match')
    },
  }),
]

const mobile: Rule[] = [
  {
    required: true,
    message: 'Please input your mobile number!',
  },
  {
    pattern: /^\d{8,11}$/,
    message: 'Invalid mobile number',
  },
]

const building: Rule[] = [
  {
    required: true,
    message: 'Please input your building number!',
  },
  {
    type: 'number',
    message: 'Building number must be a number',
  },
]

const company: Rule[] = [
  {
    required: true,
    message: 'Please input your company name!',
  },
  { max: 100, message: 'Company name is too long' },
]

const lastname: Rule[] = [
  {
    required: true,
    message: 'Please input your last name!',
  },
]

const name: Rule[] = [
  {
    required: true,
    message: 'Please input your first name!',
  },
]

export { building, company, confirmPassword, email, lastname, mobile, name, password }
