import { LockOutlined } from '@ant-design/icons'
import { Form, Input, Typography } from 'antd'
import CMSButton from 'src/components/Styles/button.style'
import { RowCenter } from 'src/components/Styles/row.style'

const { Title } = Typography

type ChangePasswordProps = {
  onPasswordChange: (values: FormValues) => void
}

export type FormValues = {
  password: string
}

const ChangePassword = ({ onPasswordChange }: ChangePasswordProps): JSX.Element => {
  const [form] = Form.useForm()

  return (
    <>
      <Title level={3}>Reset your password</Title>
      <Form form={form} onFinish={onPasswordChange}>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <RowCenter>
          <CMSButton buttonColor="green" onClick={() => form.submit()}>
            RESET PASSWORD
          </CMSButton>
        </RowCenter>
      </Form>
    </>
  )
}

export { ChangePassword }
